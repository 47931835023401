import './index.less';

export const Footer = () => {
  return (
    <div className="flex-center footer">
      <div className="text-center">
        <p>Serve-By CF Workers 💌</p>
        <p>
          Copyright &copy; 2024-PRESENT{' '}
          <a href={import.meta.env.VITE_DEVELOPER} target="_blank">
            Blucas
          </a>
        </p>
      </div>
    </div>
  );
};
