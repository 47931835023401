import { Col, Layout, Row, Typography } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import './index.less';
import clsx from 'clsx';
import { Suspense } from 'react';
import { LoadingFallback } from '@/components/LoadingFallback';
import { useDarkTheme } from '@/utils/useDarkTheme';

const { Header } = Layout;
const { Title } = Typography;

export const Layouts = () => {
  const isDark = useDarkTheme();

  return (
    <Layout className="layouts">
      <Header>
        <Row
          justify="space-between"
          align="middle"
          className={clsx('header', isDark && 'is-dark')}
        >
          <Col>
            <div className="logo">
              <Link to="/">
                <Title level={4} className="logo-name">
                  <code>*.blucas.me</code>
                </Title>
              </Link>
            </div>
          </Col>
        </Row>
      </Header>
      <Suspense fallback={<LoadingFallback />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};
