import { pageSpy } from '@/apis/request';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, FormProps, Space, Upload, message } from 'antd';
import Title from 'antd/es/typography/Title';
import { useCallback, useState } from 'react';
import './index.less';
import dayjs from 'dayjs';

export const PageSpy = () => {
  const [uploading, setUploading] = useState(false);
  const [svg, setSvg] = useState('');
  const onDownloadSvg = useCallback(() => {
    if (!svg) return;
    const blob = new Blob([svg], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${dayjs().add(7, 'day').format('YYYY-MM-DD')}前有效.svg`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
  }, [svg]);

  const handleUpload: FormProps['onFinish'] = (data) => {
    const formData = new FormData();
    formData.append('file', data.file.file);
    setUploading(true);
    pageSpy
      .post<string>('/qrcode/to-svg', {
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((svg) => {
        setSvg(svg);
        message.success('upload successfully.');
      })
      .catch((err) => {
        message.error(err);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <>
      <Title level={3}>二维码图片转 SVG</Title>
      <Form onFinish={handleUpload} labelCol={{ span: 2 }}>
        <Form.Item name="file" label="选择文件" rules={[{ required: true }]}>
          <Upload
            multiple={false}
            accept="image/*"
            maxCount={1}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>

        {svg && (
          <Form.Item label="预览">
            <div
              className="svg-preview"
              dangerouslySetInnerHTML={{ __html: svg }}
            />
          </Form.Item>
        )}
        <Form.Item
          label="操作"
          shouldUpdate={(prev, cur) => {
            return prev.file !== cur.file;
          }}
        >
          {({ getFieldValue }) => {
            return (
              <Space>
                <Button
                  htmlType="submit"
                  disabled={!getFieldValue('file')}
                  loading={uploading}
                >
                  {uploading ? '上传中...' : '开始生成'}
                </Button>

                <Button disabled={!svg} onClick={onDownloadSvg}>
                  下载文件
                </Button>
              </Space>
            );
          }}
        </Form.Item>
      </Form>
    </>
  );
};
